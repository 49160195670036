import { createReducer } from 'redux-starter-kit';
import { closeSnackbar, nextSnackbar, createSnackbarMessage } from '../actions';


const initialState = {
  open: false,
  current: {},
  queue: []
};

function addMessageToQueue(draftState, { variant, message }) {
  draftState.queue.push({ variant, message, key: new Date().getTime() });
  //if there is a snackbar open, close it
  if (draftState.open) {
    draftState.open = false;
  } else {
    popQueueToCurrent(draftState); //display next message
  }
}

function popQueueToCurrent(draftState) {
  if (draftState.queue.length > 0) {
    draftState.current = draftState.queue.shift();
    draftState.open = true;
  }
}

/* ------------------------------*/

function openNextSnackbar(state, action) {
  popQueueToCurrent(state);
}

function createNewMessage(state, action) {
  const { variant, message } = action.payload;
  addMessageToQueue(state, { variant, message });
}

function close(state, action) {
  state.open = false;
}

export default createReducer(initialState, {
  [closeSnackbar]: close,
  [nextSnackbar]: openNextSnackbar,
  [createSnackbarMessage]: createNewMessage
});
