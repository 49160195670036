import React, { Component } from 'react';
import {
  //withScriptjs,
  withGoogleMap,
  GoogleMap,
  Polyline,
  GroundOverlay
} from 'react-google-maps';
import { MAP } from 'react-google-maps/lib/constants';
import PropTypes from 'prop-types';

import DiceMarker from './DiceMarker';
import MapControlContainer from '../containers/MapControlsContainer';
import withErasable from '../containers/withErasable';

const ErasableDiceMarker = withErasable(DiceMarker);
const ErasablePolyline = withErasable(Polyline);

//must include a script tag in html file for javascript API
const BaseMap = withGoogleMap(({ children, setMapRef, ...props }) => (
  <GoogleMap
    ref={setMapRef}
    defaultOptions={{
      fullscreenControl: false,
      streetViewControl: false,
      mapTypeId: window.google.maps.MapTypeId.SATELLITE,
      gestureHandling: 'greedy'
    }}
    {...props}
  >

    {children}

  </GoogleMap>
));

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _map: null
    };
  }

  setMapRef = (ref) => {
    if (ref) {
      this.setState({ _map: ref.context[MAP] });
      // ref.context[MAP].addListener('center_changed', () => {
      //   console.log('center changed');
      // });
      // let south, west, north,east;
      // let crds=0;
      // const that=this;
      // const listener = ref.context[MAP].addListener('click', (event) => {
      //   if (crds == 0) {
      //     north = event.latLng.lat();
      //     east = event.latLng.lng();
      //     crds+=2;
      //   } else {
      //     south = event.latLng.lat();
      //     west = event.latLng.lng();
      //     crds+=2;
      //   }
      //   if (crds == 4)
      //     {
      //       console.log({south,west,north,east});
      //       that.props.onUpdateOverlay({
      //         imgSrc: 'HB/Opt_05.png',
      //         bounds: {south,west,north,east}
      //       });
      //       crds=0; //reset
      //     }
      // });
    }
  };

  parsePolylines = (polylines) => {
    const lines = Object.keys(polylines).map((id) => {
      const line = polylines[id];

      return (
        <ErasablePolyline
          key={id}
          shapeId={id}
          path={line.path}
          options={{
            strokeColor: line.color,
            strokeOpacity: 1,
            strokeWeight: 5
          }}
          readonly={this.props.readonly}
        />
      );
    });

    return lines;
  };

  parseDiceMarkers = (markers) => {
    //const filter = this.props.visibilityFilter.diceFilter;
    const dice = Object.keys(markers).map((id) => {
      const die = markers[id];
      return (
        <ErasableDiceMarker
          key={id}
          shapeId={id}
          showInfo={die.showInfo}
          diceType={die.type}
          problem={die.problem}
          potential={die.potential}
          solution={die.solution}
          position={die.position}
          onClick={() => this.props.onDieClick(id)}
          onCloseClick={() => this.props.onDieCloseClick(id)}
          onDiceTypeChange={(newType) =>
            this.props.onDieTypeChange(id, newType)
          }
          onContentChange={(newContent) =>
            this.props.handleUpdateContent(id, newContent)
          }
          readonly={this.props.readonly}
        />
      );
    });

    return dice;
  };

  render() {
    //console.log(this.props.overlay);
    return (
      <BaseMap
        containerElement={
          <div
            style={{
              height: `100%`
            }}
          />
        }
        mapElement={
          <div
            style={{
              height: `100%`
            }}
          />
        }
        setMapRef={this.setMapRef}
        center={this.props.center}
        zoom={this.props.zoom+(this.props.readonly ? 1 :0)}
        defaultCenter={this.props.center}
        defaultZoom={this.props.zoom +(this.props.readonly ? 1 :0)}
      >
        {this.props.overlay && <GroundOverlay
          key={this.props.overlay.imgSrc+this.props.overlay.bounds.south+this.props.overlay.bounds.north}
          defaultUrl={"/images/overlays/" + this.props.overlay.imgSrc}
          defaultBounds={this.props.overlay.bounds}
          defaultOpacity={1.0}
          onClick={(event) => {
            window.google.maps.event.trigger(this.state._map, 'click', event);
          }}
        />}
        {!this.props.readonly &&
          <GroundOverlay
            key={'HB/LEGEND-01.png'}
            defaultUrl={"/images/overlays/HB/LEGEND-01.png"}
            defaultBounds={{ south: -33.85820592293952, west: 151.08161840583497, north: -33.85093551998294, east: 151.08610305930787 }}
            defaultOpacity={1.0}
            onClick={(event) => {
              window.google.maps.event.trigger(this.state._map, 'click', event);
            }}
          />
        }

        {this.props.children}
        {this.parsePolylines(this.props.polylines)}
        {this.parseDiceMarkers(this.props.dice)}

        {!this.props.readonly ? (
          <MapControlContainer mapInstance={this.state._map} />
        ) : null}
      </BaseMap>
    );
  }
}

Map.propTypes = {
  // defaultCenter: PropTypes.objectOf(PropTypes.number).isRequired,
  // defaultZoom: PropTypes.number.isRequired,
  children: PropTypes.any,
  setMapRef: PropTypes.func,
  polylines: PropTypes.object,
  dice: PropTypes.object,
  overlay: PropTypes.object,
  onUpdateOverlay: PropTypes.func,
  onDieClick: PropTypes.func, //args: shapeId
  onDieCloseClick: PropTypes.func, //args: shapeId
  onDieTypeChange: PropTypes.func, //args: shapeId, newType
  onContentChange: PropTypes.func,
  center: PropTypes.object,
  zoom: PropTypes.number,
  readonly: PropTypes.bool,
  visibilityFilter: PropTypes.object
};

export default Map;