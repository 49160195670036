import { createReducer } from 'redux-starter-kit';
import { scenarioLoaded, fetchGameBegin } from '../actions';

function updateScenario(state, action) {
  const { latitude, longitude, zoomLevel } = action.payload.mapCenter;
  state.center = { lat: Number(latitude), lng: Number(longitude) };
  state.zoomLevel = Number(zoomLevel);
}

function resetCenter(state, action) {
  state.center = { lat: 0, lng: 0 };
  state.zoomLevel = 0;
}

export default createReducer(
  {},
  {
    [scenarioLoaded]: updateScenario,
    [fetchGameBegin]: resetCenter //to fix map not rendering polylines after going from /viewgame to /game (by force rerender)
  }
);
