import React, { Component } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Marker } from 'react-google-maps';
import { InfoBox } from 'react-google-maps/lib/components/addons/InfoBox';

import { DICE_RED, DICE_YELLOW, DICE_GREEN } from '../constants/diceColors';
import CircleCheckbox from './CircleCheckbox';

import diceBubble from '../images/Dice_bubble_blank.png';
import checkmark from '../images/checkmark-flat.svg';
import redDice from '../images/DiceRedT.png';
import yellowDice from '../images/DiceYellowT.png';
import greenDice from '../images/DiceGreenT.png';

const diceIcons = {
  [DICE_RED]: redDice,
  [DICE_YELLOW]: yellowDice,
  [DICE_GREEN]: greenDice
};

class DiceMarker extends Component {
  static propTypes = {
    shapeId: PropTypes.string,
    showInfo: PropTypes.bool,
    diceType: PropTypes.oneOf([DICE_RED, DICE_GREEN, DICE_YELLOW]),
    problem: PropTypes.string,
    potential: PropTypes.string,
    solution: PropTypes.string,
    position: PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number })
      .isRequired,
    onClick: PropTypes.func,
    onCloseClick: PropTypes.func,
    onDiceTypeChange: PropTypes.func, //args: newType
    onContentChange: PropTypes.func, //args: Object{problem, potential, solution}
    readonly: PropTypes.bool,
    visible: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      problem: props.problem || '',
      potential: props.potential || '',
      solution: props.solution || ''
    };
    this.redCheckboxRef = React.createRef();
    this.yellowCheckboxRef = React.createRef();
    this.greenCheckboxRef = React.createRef();
    this.closeButtonRef = React.createRef();
  }

  onInfoBoxReady = () => {
    this.closeButtonRef.current.addEventListener('click', () => {
      this.props.onCloseClick();
    });

    if (this.props.readonly) {
      return;
    }

    this.redCheckboxRef.current.addEventListener('click', () => {
      this.props.onDiceTypeChange(DICE_RED);
    });
    this.yellowCheckboxRef.current.addEventListener('click', () => {
      this.props.onDiceTypeChange(DICE_YELLOW);
    });
    this.greenCheckboxRef.current.addEventListener('click', () => {
      this.props.onDiceTypeChange(DICE_GREEN);
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const newProps = this.props;
    if (
      prevProps.problem !== newProps.problem ||
      prevProps.potential !== newProps.potential ||
      prevProps.solution !== newProps.solution
    ) {
      this.setState({
        problem: newProps.problem || '',
        potential: newProps.potential || '',
        solution: newProps.solution || ''
      });
    }
  }

  handleContentUpdated = () => {
    if (!this.props.onContentChange || this.props.readonly) {
      return;
    }

    const { problem, potential, solution } = this.state;
    this.props.onContentChange({
      problem,
      potential,
      solution
    });
  };

  render() {
    return (
      <Marker
        icon={diceIcons[this.props.diceType]}
        onClick={this.props.onClick}
        position={this.props.position}
      >
        <InfoBox
          visible={this.props.showInfo}
          options={{
            alignBottom: true,
            maxWidth: 0,
            pixelOffset: new window.google.maps.Size(-4, -15),
            closeBoxMargin: '10px 20px 2px 2px',
            closeBoxURL: '',
            enableEventPropagation: false,
            disableAutoPan: false
          }}
          onDomReady={this.onInfoBoxReady}
        >
          <Background>
            <CloseButton>
              <img ref={this.closeButtonRef} src={checkmark} alt="" />
            </CloseButton>
            <Bubble>
              <InputLine>
                <CircleCheckbox
                  active={this.props.diceType === DICE_RED && true}
                  color="red"
                  ref={this.redCheckboxRef}
                />
                <textarea
                  rows="2"
                  cols="30"
                  type="text"
                  value={this.state.problem}
                  onChange={(e) => this.setState({ problem: e.target.value })}
                  onBlur={this.handleContentUpdated}
                  readOnly={this.props.readonly}
                />
              </InputLine>

              <InputLine>
                <CircleCheckbox
                  active={this.props.diceType === DICE_YELLOW && true}
                  color="yellow"
                  ref={this.yellowCheckboxRef}
                />
                <textarea
                  rows="2"
                  cols="30"
                  type="text"
                  value={this.state.potential}
                  onChange={(e) => this.setState({ potential: e.target.value })}
                  onBlur={this.handleContentUpdated}
                  readOnly={this.props.readonly}
                />
              </InputLine>

              <InputLine>
                <CircleCheckbox
                  active={this.props.diceType === DICE_GREEN && true}
                  color="green"
                  ref={this.greenCheckboxRef}
                />
                <textarea
                  rows="2"
                  cols="30"
                  type="text"
                  value={this.state.solution}
                  onChange={(e) => this.setState({ solution: e.target.value })}
                  onBlur={this.handleContentUpdated}
                  readOnly={this.props.readonly}
                />
              </InputLine>
            </Bubble>
          </Background>
        </InfoBox>
      </Marker>
    );
  }
}
const bWidth=450*0.8,bHeight=300*0.8;
const Background = styled.div`
  background-image: url(${diceBubble});
  background-size: ${bWidth}px ${bHeight}px;
  width: ${bWidth}px;
  height: ${bHeight}px;
  padding: 50px;
  padding-left: 58px;
  padding-top: 41px;
  position: relative;
`;

const CloseButton = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: solid 0.5px black;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  & img {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
`;

const Bubble = styled.div`
  width: ${bWidth-100}px;
  height: ${bHeight-100}px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const InputLine = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  & textarea {
    border-radius: 2px;
    resize: none;
    border: solid 1px grey;
  }
`;

export default DiceMarker;
