import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const SearchField = styled.input.attrs({
  type: 'text'
})`
  margin: 10px;
  height: 30px;
`;

class PlacesAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.autocompleteInstance = null;
  }

  static propTypes = {
    onPlaceLoaded: PropTypes.func,
    bounds: PropTypes.object //to specify the bias when searching
  };

  componentDidMount() {
    this.autocompleteInstance = new window.google.maps.places.Autocomplete(
      this.inputRef.current
    );
    this.autocompleteInstance.setFields(['geometry']);
    this.autocompleteInstance.addListener(
      'place_changed',
      this.handlePlaceChanged
    );
  }

  componentDidUpdate() {
    if (this.props.bounds)
      this.autocompleteInstance.setBounds(this.props.bounds);
  }

  handlePlaceChanged = () => {
    const place = this.autocompleteInstance.getPlace();
    this.props.onPlaceLoaded(place);
  };

  render() {
    return <SearchField ref={this.inputRef} placeholder="Enter a place" />;
  }
}

export default PlacesAutocomplete;
