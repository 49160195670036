import { createReducer } from 'redux-starter-kit';
import { updateVisibilityFilter, fetchGameBegin } from '../actions';
import penColors from '../constants/penColors';
import diceColors from '../constants/diceColors';

function update(state, action) {
  return action.payload;
}

const linesFilter = {};
penColors.forEach((color) => (linesFilter[color] = true));
const diceFilter = {};
diceColors.forEach((color) => (diceFilter[color] = true));
const initialState = { linesFilter, diceFilter };

function reset(state, action) {
  return initialState;
}

export default createReducer(initialState, {
  [updateVisibilityFilter]: update,
  [fetchGameBegin]: reset
});
