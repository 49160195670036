export const PEN_RED = 'red';
export const PEN_BLUE = 'blue';
export const PEN_GREEN = 'green';
export const PEN_ORANGE = 'orange';
export const PEN_BLACK = 'black';

export function diceColorToString(diceColor) {
  return diceColor; //TODO: in case we want to change color from string to hex code
}

export default [PEN_RED, PEN_BLUE, PEN_GREEN, PEN_ORANGE, PEN_BLACK];
