import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Paper, Divider, Fab , Button} from '@material-ui/core';
import {TextField, Select, InputLabel, MenuItem} from '@material-ui/core';
import { Accordion,AccordionSummary,AccordionDetails } from '@material-ui/core';
import ColorPicker from './ColorPicker';
import { DeleteForever } from '@material-ui/icons';


const StyledPanelDetails = styled(AccordionDetails)`
  && {
    margin-top: 10px;
    flex-direction: column;
  }
`;

const QuestionCard = styled(Paper)`
  width: 90%;
  padding: 10px;
  text-align: left;
  margin: 10px;
`;

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const ExtendedFab = styled(Fab).attrs({
  variant: 'extended'
})`
  && {
    width: min-content;
    margin: 10px;
    background-color: ${(props) => props.backgroundColor};
  }
`;

//TODO: using onBlur to update form => might cause some mismatch between state and DOM when submit
export default class CreateQuestion extends React.Component {
  static propTypes = {
    categories: PropTypes.object,
    handleAddCategory: PropTypes.func.isRequired, //signature function()
    handleAddQuestion: PropTypes.func.isRequired, //signature function(categoryId): function
    handleDeleteCategory: PropTypes.func.isRequired, //function(categoryId): function
    handleDeleteQuestion: PropTypes.func.isRequired, //function(categoryId, questionIndex): function
    handleCategoryTitleChange: PropTypes.func.isRequired, // categoryId => function(event)
    handleCategoryColorChange: PropTypes.func.isRequired, //categoryId => function(color)
    handleQuestionTitleChange: PropTypes.func.isRequired, // (categoryId, questionIndex) => function(event)
    handleQuestionContentChange: PropTypes.func.isRequired, // (categoryId, questionIndex) => function(event)
    handleQuestionStepChange:  PropTypes.func.isRequired,
    handleQuestionInspFolderChange: PropTypes.func.isRequired
  };

  parseQuestions = (categories) => {
    return Object.entries(categories).map((entry, index) => {
      const [catKey, category] = entry;
      const questions = category.questions;

      return (
          <Accordion defaultExpanded key={index}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <span style={{ color: category.color }}>
                {index + 1}. {category.title}
              </span>
            </AccordionSummary>
            <Divider middle="true" />
            <StyledPanelDetails
              style={{
                marginTop: 10
              }}
            >
              <h3>Category</h3>
              <FormRow>
                <TextField
                  label="Category title"
                  inputProps={{
                    size: 30
                  }}
                  onBlur={this.props.handleCategoryTitleChange(catKey)}
                  defaultValue={category.title}
                />
                <ColorPicker
                  colors={[
                    'rgba(4, 231, 98, 1)',
                    'rgba(245, 183, 0, 1)',
                    'rgba(220, 0, 115, 1)',
                    'rgba(0, 139, 248, 1)',
                    'rgba(137, 252, 0, 1)'
                  ]}
                  onSelectColor={this.props.handleCategoryColorChange(catKey)}
                  selectedColor={category.color}
                />
                <Button
                  color="secondary"
                  onClick={this.props.handleDeleteCategory(catKey)}
                >
                  Delete <DeleteForever />
                </Button>
              </FormRow>
              <h3>Questions</h3>

              {questions.map((question, qIndex) => {
                return (
                  <QuestionCard key={qIndex}>
                    <FormRow>
                      <TextField
                        label="Title"
                        fullWidth
                        defaultValue={question.title}
                        onBlur={this.props.handleQuestionTitleChange(
                          catKey,
                          qIndex
                        )}
                      />
                      <Button
                        color="secondary"
                        onClick={this.props.handleDeleteQuestion(
                          catKey,
                          qIndex
                        )}
                      >
                        Delete <DeleteForever />
                      </Button>
                    </FormRow>
                    <br />
                    <TextField
                      multiline
                      label="Question"
                      fullWidth
                      rows={10}
                      defaultValue={question.content}
                      onBlur={this.props.handleQuestionContentChange(
                        catKey,
                        qIndex
                      )}
                    />
                    <InputLabel id="demo-simple-select-label" style={{marginTop:'15px'}}>Step</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      fullWidth
                      id="demo-simple-select"
                      value={question.gameplayStep_id || 0}
                      onChange={this.props.handleQuestionStepChange(catKey, qIndex )}
                    >
                      <MenuItem value={0}>(none)</MenuItem>
                      <MenuItem value={1}>DRAW</MenuItem>
                      <MenuItem value={3}>DICE</MenuItem>
                      <MenuItem value={8}>LOGBOOK</MenuItem>
                    </Select>
                    <TextField
                      label="Inspiration folder"
                      inputProps={{ size: 30 }}
                      onBlur={this.props.handleQuestionInspFolderChange(
                        catKey,
                        qIndex
                      )}
                      defaultValue={question.inspFolder}
                    />
                  </QuestionCard>
                );
              })}

              <ExtendedFab
                color="primary"
                onClick={this.props.handleAddQuestion(catKey)}
              >
                <AddIcon /> question
              </ExtendedFab>
            </StyledPanelDetails>
          </Accordion>
      );
    });
  };

  render() {
    return (
      <>
        {this.parseQuestions(this.props.categories)}
        <ExtendedFab onClick={this.props.handleAddCategory}>
          <AddIcon />
          category
        </ExtendedFab>
      </>
    );
  }
}
