import { connect } from 'react-redux';

import ColorPicker from '../components/ColorPicker';
import { changePenColor } from '../actions';

const mapStateToProps = (state) => {
  return {
    selectedColor: state.colorSelected
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSelectColor: (color) => dispatch(changePenColor(color))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ColorPicker);
