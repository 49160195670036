import { createReducer } from 'redux-starter-kit';

import { changePenColor } from '../actions';
import penColors from '../constants/penColors';

function updatePenColor(state, action) {
  return action.payload;
}

const colorSelected = createReducer(penColors[0], {
  [changePenColor]: updatePenColor
})

export default colorSelected;