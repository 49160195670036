import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Amplify from 'aws-amplify';



Amplify.configure({
    Auth: {
      // REQUIRED - Amazon Cognito Identity Pool ID
      identityPoolId: "ap-southeast-2:3ebe2f0c-0f2c-41e3-8045-1dd378751c30",
      // REQUIRED - Amazon Cognito Region
      mandatorySignIn: false,
      region: "ap-southeast-2",
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: "ap-southeast-2_Lo3k88wJ3",
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: "1ego2nrsks0rgh2jdqhtodb46g"
    },
    API: {
      endpoints: [
        {
          name: "ArkiNopoly-API",
          endpoint: "https://ilez4u2zn9.execute-api.ap-southeast-2.amazonaws.com/dev",
          region: "ap-southeast-2"
  
        }
      ]
    },
    Storage: {
      AWSS3: {
        bucket: 'arkicity-inspirations', //REQUIRED -  Amazon S3 bucket
        region: 'ap-southeast-2', //OPTIONAL -  Amazon service region
      }
    }
  });


ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
