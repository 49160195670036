import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import cardStackBg from '../images/inspiration_slider_stack.png';
import arrowLeft from '../images/Arrow_left.png';
import arrowRight from '../images/Arrow_right.png';

function mod(n, p) {
  return n - p * Math.floor(n / p);
}

const DialogContent = styled.div`
  flex: 1 1 auto;
  padding: 0 24px 24px;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  & > .nav-icon {
    height: 6vw;
    cursor: pointer;
  }
`;

const ImageWindow = styled.div`
  background-image: url(${cardStackBg});
  background-size: 100% 100%;
  padding: 57px;
  & > img {
    max-width: 100%; 
    max-height: 75vh;
  }
`;

export default class InspirationImageModal extends React.Component {
  static propTypes = {
    images: PropTypes.arrayOf(PropTypes.string),
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired
  };
  static defaultProps = {
    images: ['https://via.placeholder.com/300']
  };
  constructor(props) {
    super(props);
    this.state = {
      currentImgIndex: 0
    };
  }

  handleIncrementImage = (steps) => (e) => {
    this.setState((state) => {
      if (this.props.images.length !== 0) {
        return {
          currentImgIndex: mod(
            state.currentImgIndex + steps,
            this.props.images.length
          )
        };
      }
    });
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        fullWidth
        maxWidth="lg"
        onClose={this.props.onClose}
      >
        <DialogTitle>Inspirational cards</DialogTitle>
        <DialogContent>
          <img
            className="nav-icon"
            src={arrowLeft}
            alt=""
            onClick={this.handleIncrementImage(-1)}
          />
          <ImageWindow>
            {/* //TODO: temporary hostname to load images, must change */}
            <img src={this.props.images[this.state.currentImgIndex]} alt="" />
          </ImageWindow>
          <img
            className="nav-icon"
            src={arrowRight}
            alt=""
            onClick={this.handleIncrementImage(1)}
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={this.props.onClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
