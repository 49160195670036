import React from 'react';
//import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { createNextState } from 'redux-starter-kit';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import penColors from '../constants/penColors';
import diceColors from '../constants/diceColors';

class FilterModal extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func, //applying new filters, args: this.state
    linesFilter: PropTypes.object,
    diceFilter: PropTypes.object
  };

  constructor(props) {
    super(props);

    const linesFilter = {};
    penColors.forEach((color) => (linesFilter[color] = true));
    const diceFilter = {};
    diceColors.forEach((color) => (diceFilter[color] = true));

    this.state = {
      linesFilter,
      diceFilter
    };
  }

  loadFilters = () => {
    const { linesFilter, diceFilter } = this.props;
    this.setState({ linesFilter, diceFilter });
  };

  handleLinesFilterChange = (color) => (event) => {
    const checked = event.target.checked;
    this.setState((state) =>
      createNextState(state, (draft) => {
        draft.linesFilter[color] = checked;
      })
    );
  };

  handleDiceFilterChange = (color) => (event) => {
    const checked = event.target.checked;
    this.setState((state) =>
      createNextState(state, (draft) => {
        draft.diceFilter[color] = checked;
      })
    );
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        onEnter={this.loadFilters}
        onClose={this.props.onClose}
      >
        <DialogTitle>Filter shapes on map</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            {/* <FormLabel component="legend">Select visible lines</FormLabel>
            <FormGroup row>
              {penColors.map((color ,ind) => (
                <FormControlLabel
                  key={ind}
                  control={
                    <Checkbox
                      checked={this.state.linesFilter[color]}
                      onChange={this.handleLinesFilterChange(color)}
                    />
                  }
                  label={color}
                  css={`
                    svg {
                      color: ${color};
                    }
                  `}
                />
              ))}
            </FormGroup> */}
            <FormControl component="fieldset">
              <FormLabel>Select visible dice</FormLabel>
              <FormGroup row>
                {diceColors.map((color,ind) => (
                  <FormControlLabel
                    key={ind}
                    control={
                      <Checkbox
                        checked={this.state.diceFilter[color]}
                        onChange={this.handleDiceFilterChange(color)}
                      />
                    }
                    label={color}
                    css={`
                      svg {
                        color: ${color};
                      }
                    `}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() =>
              this.props.onSubmit(this.state) && this.props.onClose()
            }
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default FilterModal;
