const stepTypes = {
  DRAWING: 1,
  DICE: 2,
  LOGBOOK: 3
};

export const numberToStepType = (num) => {
  const input = Number(num);
  if (Number.isNaN(input)) {
    throw new Error('num is not a valid Number');
  } else if (input >= 1 && input <= 2) {
    return stepTypes.DRAWING;
  } else if (input >= 3 && input <= 7) {
    return stepTypes.DICE;
  } else if (input === 8) {
    return stepTypes.LOGBOOK;
  }
};

export default stepTypes;
