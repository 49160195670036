import { createReducer } from 'redux-starter-kit';
import shortid from 'shortid';

import {
  toggleDiceInfo,
  hideDiceInfo,
  changeDiceType,
  updateDiceContent,
  addNewDie,
  deleteShape,
  gameStateLoaded,
  extraGameStateLoaded
} from '../actions';
import { DICE_RED, DICE_GREEN, DICE_YELLOW } from '../constants/diceColors';
import { normalize } from '../utils/dataNormalizer';

function toggle(state, action) {
  const shapeId = action.payload;
  state[shapeId].showInfo = !state[shapeId].showInfo;
}

function hide(state, action) {
  const shapeId = action.payload;
  state[shapeId].showInfo = false;
}

function changeType(state, action) {
  const shapeId = action.payload.shapeId;
  const newType = action.payload.newType;
  state[shapeId].type = newType;
}

function updateContent(state, action) {
  const shapeId = action.payload.shapeId;
  const { problem, potential, solution } = action.payload.newContent;
  state[shapeId].problem = problem;
  state[shapeId].potential = potential;
  state[shapeId].solution = solution;
}

function addDie(state, action) {
  const shapeId = shortid.generate();
  const { lat, lng } = action.payload;
  state[shapeId] = {
    type: DICE_RED,
    position: { lat, lng },
    showInfo: true
  };
}
function deleteDieReducer(state, action) {
  const shapeId = action.payload;
  delete state[shapeId];
}

function decodeDiceGeoJson(featureCollection) {
  if (!featureCollection) return {};

  const { features } = featureCollection;
  const points = features.filter((feat) => feat.geometry.type === 'Point');

  const dice = points.map((point, index) => {
    const coordinate = point.geometry.coordinates;
    const position = { lng: coordinate[0], lat: coordinate[1] };

    const props = point.properties;

    const shapeId = props.shapeId || shortid.generate();
    const problem = props.problem || null;
    const potential = props.potential || null;
    const solution = props.solution || props.infoWindowText || null;
    let type = null;
    switch (props.diceType) {
      case 'Red':
        type = DICE_RED;
        break;
      case 'Green':
        type = DICE_GREEN;
        break;
      case 'Yellow':
        type = DICE_YELLOW;
        break;
      default:
        type = props.diceType;
    }

    return {
      position,
      type,
      problem,
      potential,
      solution,
      shapeId,
      showInfo: false
    };
  });

  return normalize(dice, 'shapeId');
}

function loadDice(state, action) {
  return decodeDiceGeoJson(action.payload);
}

function loadExtraDice(state, action) {
  const dice = decodeDiceGeoJson(action.payload);
  return { ...state, ...dice };
}

export default createReducer(
  {},
  {
    [toggleDiceInfo]: toggle,
    [hideDiceInfo]: hide,
    [changeDiceType]: changeType,
    [updateDiceContent]: updateContent,
    [addNewDie]: addDie,
    [deleteShape]: deleteDieReducer,
    [gameStateLoaded]: loadDice,
    [extraGameStateLoaded]: loadExtraDice
  }
);
