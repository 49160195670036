import React from 'react';
//import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
//import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { Snackbar, SnackbarContent, IconButton } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import Spinner from '@material-ui/core/CircularProgress';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
  loading: Spinner
};

const variantBgColor = {
  success: '#43a047',
  warning: '#ffa000',
  error: '#d32f2f',
  info: '#1976d2',
  loading: '#1976d2'
};

const ContentWrapper = styled(SnackbarContent)`
  && {
    background-color: ${(props) => variantBgColor[props.variant] || null};
  }
`;

const Message = styled.span`
  display: flex;
  align-items: center;
  & > svg {
    margin: auto 5px;
  }
`;

const CustomSnackbar = ({
  message,
  open,
  key,
  onClose,
  onExited,
  variant,
  autoHideDuration = 3000,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left'
  }
}) => {
  const Icon = variantIcon[variant];

  return (
    <Snackbar
      open={open}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      onExited={onExited}
      autoHideDuration={autoHideDuration}
      key={key}
    >
      <ContentWrapper
        message={
          <Message>
            {Icon ? <Icon /> : null}
            {message}
          </Message>
        }
        action={
          <IconButton aria-label="Close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        }
      />
    </Snackbar>
  );
};

export default CustomSnackbar;
