import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { configureStore } from 'redux-starter-kit';
import { Provider } from 'react-redux';
import history from './history';
import reducer from './reducers';
import './App.css';
import Home from './pages/Home';
import Game from './pages/Game';
import GamesPlayed from './pages/GamesPlayed';
import CreateScenario from './pages/CreateScenario';
import ViewGame from './pages/ViewGame';
import NetworkRequestFeedback from './containers/NetworkRequestFeedback';
//import PrivateRoute from './components/PrivateRoute';
import { AmplifyAuthenticator , AmplifySignIn, AmplifySignUp, AmplifyConfirmSignUp } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import {Auth} from 'aws-amplify';


const store = configureStore({
  reducer: reducer,
  devTools: process.env.NODE_ENV !== 'production'
});


const My404Component = ({ location }) => (
  <div>
    <h5>
      404 - Not found  
    </h5>
  </div>
);

const loginUI = () => (
<div className="AuthContainer" >
    <AmplifyAuthenticator  usernameAlias='email' >
    <AmplifySignUp
        slot="sign-up"
        usernameAlias="email"
        formFields={[
          {
            type: "email",
            label: "Email address",
            placeholder: "Enter your email address...",
            required: true,
          },
          {
            type: "given_name",
            label: "Given name(s)",
            placeholder: "Enter given name(s) here...",
            required: true,
          },
          {
            type: "family_name",
            label: "Surname",
            placeholder: "Enter surname here...",
            required: true,
          },
          {
            type: "password",
            label: "Password",
            placeholder: "Please choose a password...",
            required: true,
          }
        ]} 
      />
      <AmplifySignIn slot="sign-in"  usernameAlias='email' hideSignUp={true}></AmplifySignIn>
      <AmplifyConfirmSignUp usernameAlias='email' headerText="Please check your email for verification code" slot="confirm-sign-up"></AmplifyConfirmSignUp>
    </AmplifyAuthenticator>
    </div>);




const App = () => {
  const [authState, setAuthState] = React.useState();
  //const [user, setUser] = React.useState();


  React.useEffect(() => {

    if(authState === undefined) {
      Auth.currentAuthenticatedUser().then(authData => {
        setAuthState(AuthState.SignedIn);
        //setUser(authData);
      }).catch(()=>{});
    }

    onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      //setUser(authData)
    });
  });

  return authState === AuthState.SignedIn ? (
    <Router history={history}>
      <Provider store={store}>
        <div className="App">
          <div>
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/game/:id" component={Game} />
              <Route path="/games" component={GamesPlayed} />
              <Route
                path="/createscenario" component={CreateScenario}
              // render={(props) => <  {...props} />}
              />
              <Route
                path="/updatescenario/:id" component={CreateScenario}
              />
              <Route path="/viewgame" component={ViewGame} />
              <Route path="/porr" component={(props) => <ViewGame ids="241;240;239;238;236"  {...props}  />} /> 
              <Route path="/hb-pink" component={(props) => <ViewGame ids="297;299;301;305;303"  {...props}  />} />
              <Route path="/hb-blue" component={(props) => <ViewGame ids="298;300;302;306;304"  {...props}  />} />
              <Route path="/hb-purple" component={(props) => <ViewGame ids="342;343;344;345;346"  {...props}  />} />
              <Route component={My404Component} />
            </Switch>
          </div>
        </div>
        <NetworkRequestFeedback />
      </Provider>
    </Router>
  ) :
    (<Router history={history}>
      <Provider store={store}>
        <div className="App">
            <Switch>
              {/* <Route path="/porroutcome" component={(props) => <ViewGame ids="241;240;239;238;236"  {...props}  />} /> */}
              <Route path="/hb-pink" component={(props) => <ViewGame ids="297;299;301;305;303"  {...props}  />} />
              <Route path="/hb-blue" component={(props) => <ViewGame ids="298;300;302;306;304"  {...props}  />} />
              <Route path="/hb-purple" component={(props) => <ViewGame ids="342;343;344;345;346"  {...props}  />} />
              <Route component={loginUI} />
            </Switch>
        </div>
      </Provider>
    </Router>);
}


export default App;


