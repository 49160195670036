import React from 'react';
import styled from 'styled-components/macro';
import {API} from 'aws-amplify';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import BaseTableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import ErrorDialog from '../components/ErrorDialog';
import NavBar from '../components/NavBar';
import history from '../history';
import { Link } from 'react-router-dom';
import shpwrite from 'shp-write';
import {Auth} from 'aws-amplify';

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const TableCell = styled(BaseTableCell)`
  && {
    padding-right: 20px;
  }
`;

class GameList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      games: [],
      currentUser: null,
      error: null
    };
  }

  componentDidMount() {
    Auth.currentAuthenticatedUser().then((user) =>{ 
      console.log(user);
      this.setState({ currentUser: user });
    });
    this.getGameList();

  }

  getGameList = async () => {
    try {
      
      const response = await API.get('ArkiNopoly-API','/allgames');
      this.setState({ games: response });
    } catch (error) {
      console.log(error);
    }
  };

  viewSelectedGames = () => {
    const idList = this.state.games.filter((game) => game.selected).map((game) => game.id);
    const query = idList.join(';');
    history.push(`/viewgame/?ids=${query}`);
  };

  exportSelected = async () => {
    const idList = this.state.games.filter((game) => game.selected).map((game) => game.id);
    const query = idList.join('-');
    const data= await API.get('ArkiNopoly-API',`/exportgamecsv/${query}`,{responseType: 'text'});
    var aDomElem = document.createElement('a');
    aDomElem.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
    aDomElem.setAttribute('download', `Export_${query}.csv`);
    aDomElem.style.display = 'none';
    document.body.appendChild(aDomElem);
    aDomElem.click();
    document.body.removeChild(aDomElem);
  };

  deleteGame = async (id) => {
    try {
      if (window.confirm(`Are you sure you want to delete game: ${id}?`)) {
        await API.del('ArkiNopoly-API',`/deletegame/${id}`);
        this.getGameList();
      }
    } catch (error) {
      alert(' Unable to delete game; make sure you are the game owner.');
      console.log(error);
    }
  };

  exportGame = async (id) => {
    console.log(id);
    const response = await API.get('ArkiNopoly-API',`/loadgame/${id}`);
    const { gameState } = response; //const { scenarioId, gameState, logbook } 
    console.log(gameState);
    const filteredFeatures=gameState.features.filter((elem)=>{
      return (elem.geometry.type === 'Point')  ;

    });
    const filteredGeoJson={
      type: gameState.type,
      features: filteredFeatures
    }
    console.log(filteredGeoJson);



    // (optional) set names for feature types and zipped folder
    let options = {
      folder: 'myshapes',
      types: {
        point: 'arkicity-dice',
        polygon: 'arkicity-polygons',
        line: 'arkicity-lines'
      }
    }
    // a GeoJSON bridge for features
    shpwrite.download(filteredGeoJson, options);

  };

  handleSelect = (game) => (event) => {
    const val = event.target.checked;
    const gameId = game.id;
    this.setState((prevState) => {
      const updatedGames = prevState.games.map((game) => {
        if (game.id !== gameId) {
          return game;
        } else {
          return { ...game, selected: val };
        }
      });

      const selectedGames = updatedGames.filter((game) => game.selected);
      const sameScenario = selectedGames.every(
        (game) => game.scenario_id === selectedGames[0].scenario_id
      ); //all scenario_id of selected games must be the same;

      if (!sameScenario) {
        //setState
        return {
          ...prevState,
          error: {
            title: 'Multiple scenario selected',
            message:
              'You can only select games of the same scenario to view together'
          }
        };
      } else {
        //setState
        return { ...prevState, games: updatedGames };
      }
    });
  };

  handleCloseError = () => {
    this.setState({ error: null });
  };

  render() {
    return (
      <>
        <NavBar auth={this.props.auth} />
        <Wrapper>
          <div style={{display:'flex', justifyContent:'flex-end', alignSelf:'flex-end', margin: '10px 5px' }}>
          <Button
            variant="contained"
            color="default"
            onClick={this.viewSelectedGames}
          >
            View selected
          </Button>
          <Button
            style={{marginLeft:'10px'}}
            variant="contained"
            color="default"
            onClick={this.exportSelected}
          >
            Export selected (csv)
          </Button>
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Select</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Scenario ID</TableCell>
                <TableCell>Created at</TableCell>
                <TableCell>Resume</TableCell>
                <TableCell>View</TableCell>
                <TableCell>Delete</TableCell>
                <TableCell>Export (URBAN pinboard)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.games.map((game,ind) => {
                return (
                  <TableRow key={ind}>
                    <TableCell>
                      <Checkbox
                        checked={!!game.selected}
                        onChange={this.handleSelect(game)}
                      />
                    </TableCell>
                    <TableCell>{game.id}</TableCell>
                    <TableCell>{game.scenario_id}</TableCell>
                    <TableCell>{game.played_at}</TableCell>
                    <TableCell>
                      {(game.player_id === this.state.currentUser.username) &&
                        <Button
                          color="primary"
                          component={Link}
                          to={`/game/${game.id}`}
                          target="_blank"
                          variant="contained"
                        >
                          Resume
                      </Button>
                      }
                    </TableCell>
                    <TableCell>
                      <Button
                        color="primary"
                        component={Link}
                        to={`/viewgame?ids=${game.id}`}
                        target="_blank"
                        variant="contained"
                        title={game.player_id}
                      >
                        View
                      </Button>
                    </TableCell>
                    <TableCell>
                      {(game.player_id === this.state.currentUser.username) &&
                        <Button
                          color="secondary"
                          onClick={() => this.deleteGame(game.id)}
                          variant="contained"
                        >
                          Delete
                      </Button>
                      }
                    </TableCell>
                    <TableCell>
                      <Button
                        color="primary"
                        onClick={() => this.exportGame(game.id)}
                        variant="contained"
                      >
                        Export
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Wrapper>
        {this.state.error ? (
          <ErrorDialog
            title={this.state.error.title}
            message={this.state.error.message}
            open={true}
            onClose={this.handleCloseError}
          />
        ) : null}
      </>
    );
  }
}

export default GameList;
