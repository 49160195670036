import { createReducer } from 'redux-starter-kit';

import { updateLogbookContent } from '../actions';

function updateContent(state, action) {
  return action.payload;
}

export default createReducer('', {
  [updateLogbookContent]: updateContent
});
