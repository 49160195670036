import { createReducer } from 'redux-starter-kit';

import { nextQuestion, prevQuestion } from '../actions';

function nextQuestionReducer(state, action) {
  return state + 1;
}

function prevQuestionReducer(state, action) {
  return state - 1;
}

const activeQuestion = createReducer(0, {
  [nextQuestion]: nextQuestionReducer,
  [prevQuestion]: prevQuestionReducer
})

export default activeQuestion;