import { createReducer } from 'redux-starter-kit';
import { loadInspImages, toggleShowInspImages } from '../actions';

function loadImages(state, action) {
  const images = action.payload;
  state.sources = images;
}

function toggleImages(state, action) {
  state.open = !state.open;
}

export default createReducer(
  { open: false, sources: [] },
  {
    [loadInspImages]: loadImages,
    [toggleShowInspImages]: toggleImages
  }
);
