import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import StepBox from '../components/StepBox';

const LEFT = 0;
const BOTTOM = 1;
const RIGHT = 2;
const TOP = 3;

// const demoQuiz = {
//   categoryIndex: 1,
//   content: 'What',
//   image: 'optional',
//   barPosition: LEFT
// };

class Board extends Component {
  static propTypes = {
    questions: PropTypes.array,
    activeIndex: PropTypes.number,
    next: PropTypes.func,
    back: PropTypes.func
  };
  static defaultProps = {
    questions: [
      {
        category: 1,
        content: 'Hello'
      },
      {
        category: 1,
        content: 'Hi'
      },
      {
        category: 1,
        content: 'Bye'
      },
      {
        category: 1,
        content: 'Hello'
      },
      {
        category: 2,
        content: 'Hi'
      },
      {
        category: 2,
        content: 'Bye'
      },
      {
        category: 2,
        content: 'Hello'
      },
      {
        category: 2,
        content: 'Hi'
      },
      {
        category: 3,
        content: 'Bye'
      },
      {
        category: 3,
        content: 'Hello'
      },
      {
        category: 3,
        content: 'Hi'
      },
      {
        category: 3,
        content: 'Bye'
      },
      {
        category: 3,
        content: 'See ya'
      }
    ]
  };

  parseQuestions = (questions) => {
    const tileCount = questions.length;
    const tilesInEachBar = Math.floor(tileCount / 4.0 + 0.5);
    let parsedQuestions = questions.map((question, index) => {
      const positionIndex = Math.floor(index / tilesInEachBar);
      const active = index === this.props.activeIndex;
      switch (positionIndex) {
        case 0:
          return { ...question, active, barPosition: LEFT };
        case 1:
          return { ...question, active, barPosition: BOTTOM };
        case 2:
          return { ...question, active, barPosition: RIGHT };
        case 3:
          return { ...question, active, barPosition: TOP };
        default:
          return { ...question, active, barPosition: TOP };
      }
    });

    return parsedQuestions;
  };

  questionsToStepBox = (questions) => {
    return questions.map((question,ind) => (
      <StepBox
        key={ind}
        active={question.active}
        image={question.image}
        color={question.color}
      />
    ));
  };

  render() {
    const parsedQuestions = this.parseQuestions(this.props.questions);

    const borderBars = {
      [LEFT]: [],
      [BOTTOM]: [],
      [RIGHT]: [],
      [TOP]: []
    };

    for (let position in borderBars) {
      borderBars[position] = parsedQuestions.filter(
        (question) => (Number(question.barPosition) === Number(position))  
      );
    }
    return (
      <BoardWrapper>
        <Center>{this.props.children}</Center>
        <BorderBar flexDirection="column" gridArea="left">
          {this.questionsToStepBox(borderBars[LEFT])}
        </BorderBar>
        <BorderBar gridArea="bottom" flexDirection="row">
          {this.questionsToStepBox(borderBars[BOTTOM])}
        </BorderBar>
        <BorderBar gridArea="right" flexDirection="column-reverse">
          {this.questionsToStepBox(borderBars[RIGHT])}
        </BorderBar>
        <BorderBar gridArea="top" flexDirection="row-reverse">
          {this.questionsToStepBox(borderBars[TOP])}
        </BorderBar>
      </BoardWrapper>
    );
  }
}

const BoardWrapper = styled.div`
  display: grid;
  grid-template-columns: 8% 1fr 8%;
  grid-template-rows: 8% 1fr 8%;
  grid-template-areas:
    'left top top'
    'left center right'
    'bottom bottom right';
  height: 100%;
  min-height: 0px; /* make sure text in speech bubble doesnt expand the column */
  flex: 1; /* take all available extra space */
`;

const BorderBar = styled.div`
  grid-area: ${(props) => props.gridArea};
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
`;

const Center = styled.div`
  grid-area: center;
`;

export default Board;
