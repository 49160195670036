import React from 'react';
import styled from 'styled-components/macro';
import { GoogleMap, withGoogleMap, withScriptjs } from 'react-google-maps';
import { MAP } from 'react-google-maps/lib/constants';
import shortid from 'shortid';
import { createNextState } from 'redux-starter-kit';
import {API} from 'aws-amplify';
import queryString from 'query-string';

import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import NavBar from '../components/NavBar';
import Autocomplete from '../components/PlacesAutocomplete';
import MapControl from '../components/MapControl';
import CreateQuestions from '../components/CreateQuestions';
//import AddImageModal from '../components/AddImageModal'
import history from '../history';
import {jsAPIUrl} from '../constants/googleAPIConsts';

const FormSection = styled(Paper)`
  width: 60%;
  min-width: 400px;
  margin: 20px auto;
  padding: 20px;
  text-align: left;
`;

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const SpacedField = styled(TextField)`
  && {
    margin: 10px;
  }
`;

class PreviewMap extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    const { lat, lng } = nextProps.center;
    if (!lat || !lng) {
      return false;
    } else {
      return true;
    }
  }
  render() {
    const { searchBounds, onPlaceLoaded, onMapMounted, ...props } = this.props;
    return (
      <GoogleMap
        ref={onMapMounted}
        defaultOptions={{ mapTypeId: window.google.maps.MapTypeId.SATELLITE }}
        {...props}
      >
        <MapControl position={window.google.maps.ControlPosition.TOP}>
          <Autocomplete onPlaceLoaded={onPlaceLoaded} bounds={searchBounds} />
        </MapControl>
      </GoogleMap>
    );
  }
}

const PreviewMapWithScript = withScriptjs(withGoogleMap(PreviewMap));

class CreateScenario extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      map: {
        lat: -34.999,
        lng: 150.644,
        zoom: 8
      },
      mapBounds: null,
      categories: {}
    };
  }

  async componentDidMount() {
    try {
      const { template } = queryString.parse(this.props.location.search);
      if (this.props.match.path.match(/\/updatescenario/)) {
        const { id } = this.props.match.params;
        const res = await API.get('ArkiNopoly-API', `/getscenario/${id}`);
        const scenario = this.parseTemplateScenario(res.scen);
        this.setState({ ...scenario, name: res.name });
      } else if (template) {

        const res = await API.get('ArkiNopoly-API', `/getscenario/${template}`);
        const scenario = this.parseTemplateScenario(res.scen);
        this.setState(scenario);
      }//if
    } catch (error) {
      console.log(error);
    }
  }

  parseTemplateScenario = (scenario) => {
    const { latitude, longitude, zoomLevel } = scenario.mapCenter;
    const map = {
      lat: Number(latitude),
      lng: Number(longitude),
      zoom: Number(zoomLevel)
    };

    const { gameCategories, gamePlayQuestions } = scenario;
    gamePlayQuestions.forEach((question) => {
      const catIndex = question.category - 1;
      if (!gameCategories[catIndex].questions) {
        gameCategories[catIndex].questions = [];
      }
      const { content, title, insp_folder } = question;
      gameCategories[catIndex].questions.push({
        content,
        title,
        'gameplayStep_id': (question.gameplayStep?.id || 0) ,
        inspFolder: insp_folder
      });
    });

    const categories = {};
    scenario.gameCategories.forEach((cat) => {
      const id = shortid.generate();
      categories[id] = {
        color: cat.colorCode,
        title: cat.title,
        questions: cat.questions || []
      };
    });

    return { categories, map };
  };

  onSearchBoxMounted = (ref) => {
    this.searchBoxRef = ref;
  };

  onMapMounted = (ref) => {
    this.mapRef = !ref ? null : ref.context[MAP];
  };

  handleNameChange = (event) => {
    this.setState({ name: event.target.value });
  };

  handleMapFieldChange = (fieldName) => (event) => {
    let value = event.target.value;
    if (!value) {
      value = null;
    } else {
      value = parseFloat(value);
    }
    this.setState(({ map }) => {
      return {
        map: {
          ...map,
          [fieldName]: value
        }
      };
    });
  };

  handleMapBoundsChange = () => {
    const center = this.mapRef.getCenter();
    const lat = center.lat();
    const lng = center.lng();
    const zoom = this.mapRef.getZoom();
    const mapBounds = this.mapRef.getBounds();
    this.setState({
      map: {
        lat,
        lng,
        zoom
      },
      mapBounds
    });
  };

  handlePlaceSelection = (place) => {
    const latLng = place.geometry.location;
    const lat = latLng.lat();
    const lng = latLng.lng();
    this.setState((prevState) => {
      return {
        map: {
          lat,
          lng,
          zoom: prevState.map.zoom
        }
      };
    });
  };

  handleAddCategory = () => {
    this.setState((prevState) => {
      const id = shortid.generate();
      return createNextState(prevState, (draftState) => {
        draftState.categories[id] = {
          title: '',
          color: '',
          questions: []
        };
      });
    });
  };

  handleAddQuestion = (categoryId) => () => {
    this.setState((prevState) => {
      return createNextState(prevState, (draftState) => {
        draftState.categories[categoryId].questions.push({
          title: '',
          content: ''
        });
      });
    });
  };

  handleDeleteCategory = (categoryId) => () => {
    this.setState((prevState) => {
      return createNextState(prevState, (draftState) => {
        delete draftState.categories[categoryId];
      });
    });
  };

  handleDeleteQuestion = (categoryId, questionIndex) => () => {
    this.setState((prevState) => {
      return createNextState(prevState, (draftState) => {
        draftState.categories[categoryId].questions.splice(questionIndex, 1);
      });
    });
  };

  handleCategoryTitleChange = (categoryId) => (event) => {
    const val = event.target.value;
    this.setState((prevState) => {
      return createNextState(prevState, (draftState) => {
        draftState.categories[categoryId].title = val;
      });
    });
  };

  handleCategoryColorChange = (categoryId) => (color) => {
    this.setState((prevState) => {
      return createNextState(prevState, (draftState) => {
        draftState.categories[categoryId].color = color;
      });
    });
  };

  handleQuestionInspFolderChange = (categoryId, questionIndex) => (event) => {
    const val = event.target.value;
    this.setState((prevState) => {
      return createNextState(prevState, (draftState) => {
        draftState.categories[categoryId].title = val;
      });
    });
  };

  handleQuestionFieldChange = (fieldName) => (categoryId, questionIndex) => (
    event
  ) => {
    const val = event.target.value;
    this.setState((prevState) => {
      return createNextState(prevState, (draftState) => {
        draftState.categories[categoryId].questions[questionIndex][
          fieldName
        ] = val;
      });
    });
  };
  

  //

  handleQuestionStepChange = (categoryId, questionIndex) => (
    event
  ) => {
    const val = event.target.value;
    this.setState((prevState) => {
      return createNextState(prevState, (draftState) => {
        draftState.categories[categoryId].questions[questionIndex][
          'gameplayStep_id'
        ] =  val;
      });
    });
  };


  submit = async () => {
    const { lat, lng, zoom } = this.state.map;
    const mapCenter = { latitude: lat, longitude: lng, zoomLevel: zoom };
    const gameCategories = [];
    const gamePlayQuestions = [];
    Object.values(this.state.categories).forEach((category, catIndex) => {
      gameCategories.push({
        title: category.title,
        colorCode: category.color
      });
      category.questions.forEach((question) => {
        gamePlayQuestions.push({
          title: question.title,
          content: question.content,
          category: catIndex + 1,
          gameplayStep: { id: question.gameplayStep_id},
          insp_folder: question.inspFolder
        });
      });
    });
    const data = {
      mapCenter,
      gameCategories,
      gamePlayQuestions
    };
    try {
      await API.post('ArkiNopoly-API', '/savescenario', {
        body: {
          name: this.state.name,
          scenarioData: data
        }
      });
      history.push('/');
    } catch (error) {
      console.log(error);
    }
  };

  update = async () => {
    const { lat, lng, zoom } = this.state.map;
    const mapCenter = { latitude: lat, longitude: lng, zoomLevel: zoom };
    const gameCategories = [];
    const gamePlayQuestions = [];
    Object.values(this.state.categories).forEach((category, catIndex) => {
      gameCategories.push({
        title: category.title,
        colorCode: category.color
      });
      category.questions.forEach((question) => {
        gamePlayQuestions.push({
          title: question.title,
          content: question.content,
          category: catIndex + 1,
          gameplayStep: { id: question.gameplayStep_id},
          insp_folder: question.inspFolder
        });
      });
    });
    const data = {
      mapCenter,
      gameCategories,
      gamePlayQuestions
    };
    const {id}=this.props.match.params;
    try {                                           
      const resp= await API.post('ArkiNopoly-API', '/updatescenario/'+id, {
        body: {
          name: this.state.name,
          scenarioData: data
        }
      });
      console.log(resp);
      alert('Scenario successfully updated.');
    } catch (error) {
      alert('Error; make sure you are the scenario owner.')
      console.log(error);
    }
  };

  render() {
    return (
      <>
        <NavBar auth={this.props.auth} />
        <h1>Create new scenario</h1>
        <FormSection square>
          <h2>Scenario name</h2>
          <SpacedField
            onChange={this.handleNameChange}
            label="Scenario name"
            value={this.state.name}
          />
          <h2>Define map area</h2>
          <Divider />
          <FormRow>
            <SpacedField
              onChange={this.handleMapFieldChange('lat')}
              label="Latitude"
              name="lat"
              type="number"
              value={this.state.map.lat || ''}
            />
            <SpacedField
              onChange={this.handleMapFieldChange('lng')}
              label="Longitude"
              name="lng"
              type="number"
              value={this.state.map.lng || ''}
            />
            <SpacedField
              onChange={this.handleMapFieldChange('zoom')}
              label="Zoom level"
              name="zoom"
              type="number"
              value={this.state.map.zoom || ''}
            />
          </FormRow>
          <PreviewMapWithScript
            googleMapURL={jsAPIUrl}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            center={{
              lat: this.state.map.lat,
              lng: this.state.map.lng
            }}
            zoom={this.state.map.zoom}
            onDragEnd={this.handleMapBoundsChange}
            onZoomChanged={this.handleMapBoundsChange}
            onMapMounted={this.onMapMounted}
            onPlaceLoaded={this.handlePlaceSelection}
            searchBounds={this.state.mapBounds}
          />

          <h2>Game questions</h2>
          <Divider />
          {}
          <CreateQuestions
            categories={this.state.categories}
            handleAddCategory={this.handleAddCategory}
            handleAddQuestion={this.handleAddQuestion}
            handleDeleteCategory={this.handleDeleteCategory}
            handleDeleteQuestion={this.handleDeleteQuestion}
            handleCategoryTitleChange={this.handleCategoryTitleChange}
            handleCategoryColorChange={this.handleCategoryColorChange}
            handleQuestionTitleChange={this.handleQuestionFieldChange('title')}
            handleQuestionContentChange={this.handleQuestionFieldChange(
              'content'
            )}
            handleQuestionStepChange={this.handleQuestionStepChange}
            handleQuestionInspFolderChange={this.handleQuestionFieldChange(
              'inspFolder'
            )}
          />
          <Divider />
          <div>
          { (this.props.match.path.match(/\/updatescenario/)) ? 
          (<Button
            style={{ margin: '10 auto' }}
            color="primary"
            variant="contained"
            size="large"
            onClick={this.update}
          >
            Update
          </Button>)
          :
            (<Button
              style={{ margin: '10 auto' }}
              color="primary"
              variant="contained"
              size="large"
              onClick={this.submit}
            >
              Submit
            </Button>)}

          </div>
        </FormSection>
        {/* <AddImageModal open={this.state.UploadModalOpen} onClose={()=>this.setState({UploadModalOpen:false})} /> */}
      </>
    );
  }
}

export default CreateScenario;
