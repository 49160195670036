import { connect } from 'react-redux';
import CustomSnackbar from '../components/CustomSnackbar';
import { closeSnackbar, nextSnackbar } from '../actions';

const getCurrentMessage = (state) => {
  const { open, current } = state.snackbarMessages;
  const { variant, message } = current;
  return { open, variant, message };
};

const mapStateToProps = (state) => {
  const { open, variant, message, key } = getCurrentMessage(state);
  return {
    open,
    variant,
    message,
    key
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClose: (event, reason) =>
      reason !== 'clickaway' && dispatch(closeSnackbar()),
    onExited: () => dispatch(nextSnackbar())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomSnackbar);
