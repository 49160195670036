import React from 'react';
import styled from 'styled-components/macro';
import { withScriptjs } from 'react-google-maps';
import { connect } from 'react-redux';
import queryString from 'query-string';
import {jsAPIUrl} from '../constants/googleAPIConsts';

import {
  fetchGame,
  updateVisibilityFilter,//fetchExtraGame,
  fetchMultipleGames
} from '../actions';
import NavBar from '../components/NavBar';
import Map from '../containers/MapContainer';
import Button from '@material-ui/core/Button';
import FilterIcon from '@material-ui/icons/Visibility';
import { changeEditMode, editModes } from '../actions';
import FilterModal from '../components/FilterModal';
import {Grid , Container} from '@material-ui/core';

// const Wrapper = styled.div`
//   height: 90vh;
//   display: flex;
// `;

const LogbookArea = styled.div`
  display: flex;
  height: 100%;
  margin: 5px;
  flex-direction: column;
  align-items: flex-start;
`;

const LogbookContent = styled.textarea.attrs({
  readOnly: true
})`
  flex: 1;
  width: 100%;
  margin: 5px;
`;

const SpacedButton = styled(Button)`
  && {
    margin: 10px;
  }
`;

class ViewGame extends React.Component {
  componentDidMount() {
    const { ids } = (queryString.parse(this.props.location.search));
    const idsString= (ids || this.props.ids);
    const idsArray=idsString ? idsString.split(';').map((e)=>Number(e)) :[];
    if (idsArray.length > 1) {
      this.props.loadMultipleGames(idsArray);
    } else if (idsArray.length === 1) {
      this.props.loadGame(idsArray[0]);
    } else {
      alert('Invalid query parameters');
    }
  }
  render() {
    return (
       <Container maxWidth='xl'>
        {!(this.props.ids) &&  <NavBar/>}
        <Grid container style={{height:'CALC(100vh - 80px)' , marginTop:'5px'}}>
          <Grid item md={9} xs={12} >
            <Map readonly />
          </Grid>
          <Grid item md={3} xs={12}>
            <LogbookArea>
              <SpacedButton variant="contained" onClick={this.props.openFilter}>
                <FilterIcon /> {' Filter'} 
              </SpacedButton>
              <h2>Logbook(s) content</h2>
              <LogbookContent value={this.props.logbook || ''} />
            </LogbookArea>
          </Grid>
        </Grid>
        <FilterModal
          open={this.props.filterIsOpen}
          onClose={this.props.closeFilter}
          linesFilter={this.props.visibilityFilter.linesFilter}
          diceFilter={this.props.visibilityFilter.diceFilter}
          onSubmit={this.props.handleFilterChange}
        />
        {this.props.ids && <div style={{height: '55px', marginTop: '5px', display:'flex', justifyContent:'center', alignItems:'center'}}>
        <img src="/images/smart-logo.png" alt="SMART Infrastructure Facility" style={{flexGrow:0, maxHeight: '45px',marginRight:'15px',marginLeft:'15px',marginTop:'5px'}} />
        <img src="/images/tfnswlogo.png" alt="TfNSW" style={{flexGrow:0, maxHeight: '50px',marginRight:'15px',marginLeft:'15px'}} />
        <img src="/images/arkilogo.png" alt="arki_lab" style={{flexGrow:0, maxHeight: '35px',marginRight:'15px',marginLeft:'15px', marginTop:'5px'}} />
        </div>}
      </Container>
    );
  }
}

const ViewGameWithScript = withScriptjs(ViewGame);
ViewGameWithScript.defaultProps = {
  googleMapURL: jsAPIUrl,
  loadingElement: (
    <div
      style={{
        height: `100%`
      }}
    />
  )
};

const mapStateToProps = (state) => {
  return {
    logbook: state.logbook,
    filterIsOpen: state.drawingMode === editModes.FILTER,
    visibilityFilter: state.visibilityFilter
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  // const { id } = queryString.parse(ownProps.location.search);
  return {
    loadGame: (id) => dispatch(fetchGame(id)),
    loadMultipleGames: (ids) => dispatch(fetchMultipleGames(ids)),
    openFilter: () => dispatch(changeEditMode(editModes.FILTER)),
    closeFilter: () => dispatch(changeEditMode(editModes.NONE)),
    handleFilterChange: (filter) => dispatch(updateVisibilityFilter(filter))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewGameWithScript);
