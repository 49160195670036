import { Component } from 'react';
import { createPortal } from 'react-dom';
import { MAP } from 'react-google-maps/lib/constants';
import PropTypes from 'prop-types'

class MapControl extends Component {
  static propTypes = {
    position: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
    className: PropTypes.string,
  };

  static defaultProps = {
    children: [],
    className: '',
  };

  static contextTypes = { [MAP]: PropTypes.object };

  constructor(props, context) {
    super(props);

    this.map = context[MAP];
    window.myMap = context[MAP]; //DEBUG
    this.controlDiv = document.createElement('div');
    this.controlDiv.style.margin = '10px';
    this.controlDiv.style.marginLeft = '10px';
    this.map.controls[props.position].push(this.controlDiv);
  }

  componentDidMount() {
    this.controlDiv.addEventListener('mousedown', e => {
      e.stopPropagation();
    })
  }

  componentWillUnmount() {
    const controlArray = this.map.controls[this.props.position].getArray()
    //the array returned by getArray() will not fire events when mutated
    for (let index in controlArray) {
      if (controlArray[index] === this.controlDiv) {
        this.map.controls[this.props.position].removeAt(index);
        break;
      }
    }
    
  }

  render() {
    const { className } = this.props;
    className && this.controlDiv.classList.add(className);

    return createPortal(this.props.children, this.controlDiv);
  }
}

export default MapControl;