import React from 'react';
import styled from 'styled-components/macro';

const StepBox = ({ color, image, active, children }) => (
  <Box color={color} active={active}>
    {image ? <Icon src={image} /> : null}
  </Box>
);

const Box = styled.div`
  background-color: ${(props) => props.color || 'white'};
  border: 1px solid black;
  box-shadow: ${(props) =>
    props.active ? '0px 0px 23px 6px rgba(0,0,0,0.75)' : 'none'};
  z-index: ${(props) => (props.active ? '1' : 'auto')};
  flex: 1;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Icon = styled.img`
  vertical-align: middle;
  width: 47px;
`;

export default StepBox;
