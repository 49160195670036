import { createReducer } from 'redux-starter-kit';
import shortid from 'shortid';

import {
  addPolyline,
  deleteShape,
  gameStateLoaded,
  extraGameStateLoaded
} from '../actions';
import { normalize } from '../utils/dataNormalizer';

function addLine(state, action) {
  const { path, color } = action.payload;
  const shapeId = shortid.generate();
  state[shapeId] = {
    path,
    color
  };
}

function deleteLine(state, action) {
  const shapeId = action.payload;
  delete state[shapeId];
}

function decodePolylinesGeoJson(featureCollection) {
  if (!featureCollection) return {};
  const { features } = featureCollection;
  const lineStrings = features.filter(
    (feat) => feat.geometry.type === 'LineString'
  );

  const polylines = lineStrings.map((line, index) => {
    const path = line.geometry.coordinates.map((coordinate) => {
      return { lng: coordinate[0], lat: coordinate[1] };
    });

    const shapeId = line.properties.shapeId || shortid.generate();

    return { path, color: line.properties.mycolor, shapeId };
  });

  return normalize(polylines, 'shapeId');
}

function loadPolylines(state, action) {
  return decodePolylinesGeoJson(action.payload);
}

function loadExtraPolylines(state, action) {
  const polylines = decodePolylinesGeoJson(action.payload);
  return { ...state, ...polylines };
}

export default createReducer(
  {},
  {
    [addPolyline]: addLine,
    [deleteShape]: deleteLine,
    [gameStateLoaded]: loadPolylines,
    [extraGameStateLoaded]: loadExtraPolylines
  }
);
