import { connect } from 'react-redux';

import { handleNextQuestion, handlePrevQuestion,  } from '../actions';
import Board from '../components/Board';

const mapStateToProps = (state) => {
  return {
    questions: state.questions,
    activeIndex: state.activeQuestion
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    next: () => dispatch(handleNextQuestion()),
    back: () => dispatch(handlePrevQuestion())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Board);