import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';

import { handleNextQuestion, handlePrevQuestion } from '../actions';
import backPng from '../images/back.png';
import nextPng from '../images/Next.png';
import leftArrow from '../images/Arrow_left.png';
import rightArrow from '../images/Arrow_right.png';

const Wrapper = styled.div`
  display: flex; 
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const NavButton = styled.div`
  display: flex;
  max-height: 50px;
  max-width: 50%;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const NavImg = styled.img`
  max-height: 50px;
  `;

const QuestionNav = ({ back, next }) => (
  <Wrapper>
    <NavButton  onClick={back}>
      <NavImg src={leftArrow} alt="" />
      <NavImg src={backPng} alt=""  />
    </NavButton>
    <NavButton  onClick={next} style={{justifyContent:'flex-end'}}>
      <NavImg src={nextPng} alt=""  />
      <NavImg src={rightArrow} alt="" />
    </NavButton>
  </Wrapper>
);

const mapDispatchToProps = (dispatch) => {
  return {
    next: () => dispatch(handleNextQuestion()),
    back: () => dispatch(handlePrevQuestion())
  };
};

export default connect(
  null,
  mapDispatchToProps
)(QuestionNav);
