import { createReducer } from 'redux-starter-kit';

import { changeEditMode, editModes } from '../actions';

const updateMode = (state, action) => {
  return action.payload;
};

const drawingMode = createReducer(editModes.NONE, {
  [changeEditMode]: updateMode
});

export default drawingMode;
