import { connect } from 'react-redux';
//import { MAP } from 'react-google-maps/lib/constants';
import { createSelector } from 'redux-starter-kit';

import {
  toggleDiceInfo,
  hideDiceInfo,
  changeDiceType,
  updateDiceContent,
  loadOverlay,
  saveGame
} from '../actions';
import Map from '../components/Map';

const getVisibleLines = createSelector(
  ['shapes.present.polylines', 'visibilityFilter.linesFilter'],
  (lines, visibleColors) => {
    const result = {};
    Object.entries(lines).forEach(([key, item]) => {
      if (visibleColors[item.color] !== false) {
        result[key] = item;
      }
    });
    return result;
  }
);

const getVisibleDice = createSelector(
  ['shapes.present.dice', 'visibilityFilter.diceFilter'],
  (dice, visibleColors) => {
    const result = {};
    Object.entries(dice).forEach(([key, item]) => {
      if (visibleColors[item.type] !== false) {
        result[key] = item;
      }
    });
    return result;
  }
);

const mapStateToProps = (state) => {
  return {
    // polylines: state.shapes.present.polylines,
    // dice: state.shapes.present.dice,
    polylines: getVisibleLines(state),
    dice: getVisibleDice(state),
    center: state.map.center,
    zoom: state.map.zoomLevel,
    visibilityFilter: state.visibilityFilter,
    overlay: state.activeOverlay
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  if (ownProps.readonly) {
    return {
      onDieClick: (shapeId) => dispatch(toggleDiceInfo(shapeId)),
      onDieCloseClick: (shapeId) => dispatch(hideDiceInfo(shapeId))
    };
  } else {
    return {
      onDieClick: (shapeId) => dispatch(toggleDiceInfo(shapeId)),
      onDieCloseClick: (shapeId) => {
        dispatch(hideDiceInfo(shapeId));
        dispatch(saveGame());
      },
      onDieTypeChange: (shapeId, newType) =>
        dispatch(changeDiceType({ shapeId, newType })),
      handleUpdateContent: (shapeId, newContent) =>
        dispatch(updateDiceContent({ shapeId, newContent })),
      onUpdateOverlay: (overlay) => dispatch(loadOverlay(overlay)),
    };
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Map);
