import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Map from '../containers/MapContainer';
import Board from '../containers/BoardContainer';
import QuestionNav from '../containers/QuestionNav';
import ActiveQuestionContent from '../containers/ActiveQuestionContent';
import { withScriptjs } from 'react-google-maps';
import { fetchGame, saveGame, toggleShowInspImages } from '../actions';
import Button from '@material-ui/core/Button';
import cardStackImg from '../images/card_stack.png';
import arrowLeft from '../images/Arrow_left.png';
import InspirationImageModal from '../components/InspirationImageModal';
import {jsAPIUrl} from '../constants/googleAPIConsts';
import {Paper ,Typography} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import {Auth} from 'aws-amplify';
import Link from '@material-ui/core/Link';

const GameWithScript = withScriptjs(
  ({
    onSave,
    inspImages,
    inspImagesIsOpen,
    toggleInspImages,
    infoAreaOpen,
    onToggleInfoArea,
    catTitle,
    catColor,
    qTitle,
    gameID,
    useremail,
    handleConfirmSignOut,
    activeOverlay,
    scenDetails
  }) => (
    <>
      <Wrapper>
        <Board>
          <Map overlay={activeOverlay} />
        </Board>
        <TogglePanel open={infoAreaOpen} onClick={onToggleInfoArea}>
          <img src={arrowLeft} alt="Open/close info area" />
        </TogglePanel>
        <InfoArea collapsed={!infoAreaOpen}>
          <Typography variant='h4' style={{margin:'10px 5px', fontWeight:'bold', color: catColor}} >{catTitle}</Typography>
          <Paper elevation={20} style={{margin:'10px 5px', height: '47vh', overflowY:'scroll'}}>
          <Typography style={{fontSize: 14, textAlign:'left', marginLeft:'15px', marginTop:'10px', fontWeight:'bold', lineHeight:'1.3em'}} color="textSecondary" variant='h5' >
          {qTitle}
        </Typography>
            <ActiveQuestionContent />
          </Paper>
          <DudeWrapper>
          <QuestionNav />
            <ImageButton
              hidden={inspImages.length === 0}
            >
              <img src={cardStackImg} alt="Inspiration images" onClick={toggleInspImages} />
            </ImageButton>
          </DudeWrapper>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              marginTop: '15px'
            }}
          >
            <SpacedButton variant="contained" onClick={onSave} startIcon={<SaveIcon />}>
              Save game
            </SpacedButton>
          </div>
          <div
            style={{
              flexGrow:1
            }}
          >
            </div>
        <Typography style={{fontSize: 12, textAlign:'center', marginBottom:'5px', alignSelf:'center'}} color="textSecondary" variant='h5' >
           Playing scenario #{scenDetails.id}: <b>{scenDetails.name}</b>
        </Typography>
        <Typography style={{fontSize: 12, textAlign:'center', marginBottom:'10px', alignSelf:'center'}} color="textSecondary" variant='h5' >
           Game ID: {gameID} -- Signed in as {useremail} (<Link onClick={handleConfirmSignOut} style={{cursor:'pointer'}}>Sign out</Link>)
        </Typography>
         
        </InfoArea>

      </Wrapper>
      <InspirationImageModal
        images={inspImages}
        open={inspImagesIsOpen}
        onClose={toggleInspImages}
      />
    </>
  )
);

GameWithScript.defaultProps = {
  googleMapURL: jsAPIUrl,
  loadingElement: (
    <div
      style={{
        height: `100%`
      }}
    />
  )
};

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
`;

const InfoArea = styled.div`
  display: flex;
  flex-direction: column; 
  align-items: flex-start;
  min-height: 0px; /* make sure text in speech bubble doesnt expand the column */
  width: ${(props) => (props.collapsed ? '0px' : '30vw')};
  transition: width 0.1s ease-out;
  & div {
    ${(props) =>
      props.collapsed &&
      'overflow: hidden !important'}; /*nicer collapse animation*/
  }
`;

const ImageButton = styled.div`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  background-color: transparent;
  border: none;
  visibility: ${(props) => props.hidden && 'hidden'};
  
  & > img {
    width: 30%;
    cursor: pointer;
  }
`;

const DudeWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SpacedButton = styled(Button)`
  && {
    margin: 10px;
  }
`;

const TogglePanel = styled.div`
  height: 100%;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px 2px;
  &:hover {
    background-color: lightgrey;
  }
  & > img {
    width: 100%;
    ${(props) => props.open && 'transform: scaleX(-1)'}; /*flip icon when open*/
  }
`;

class Game extends React.Component {
  static propTypes = {
    onMount: PropTypes.func,
    onSave: PropTypes.func,
    match: PropTypes.object, //injected by React Router
    onRedo: PropTypes.func,
    onUndo: PropTypes.func,
    canRedo: PropTypes.bool,
    canUndo: PropTypes.bool,
    inspImages: PropTypes.array,
    inspImagesIsOpen: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = { infoAreaOpen: true , useremail:'' };
  }

  componentDidMount() {
    this.props.onMount();
    Auth.currentAuthenticatedUser().then((user) => this.setState({ useremail: user.attributes.email }));
  }

  handleToggleInfoArea = () => {
    this.setState((state) => ({ infoAreaOpen: !state.infoAreaOpen }));
  };

  handleConfirmSignOut = () => {
    if (window.confirm('Are you sure you want to sign out?\nNOTE: please make sure you have saved the game.'))
    {
      Auth.signOut();
    }
  }

  render() {
    return (
      <GameWithScript
        infoAreaOpen={this.state.infoAreaOpen}
        onToggleInfoArea={this.handleToggleInfoArea}
        useremail={this.state.useremail}
        handleConfirmSignOut={this.handleConfirmSignOut}
        {...this.props}
      />
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onMount: () => dispatch(fetchGame(ownProps.match.params.id)),
    onSave: () => dispatch(saveGame()),
    toggleInspImages: () => dispatch(toggleShowInspImages())
  };
};

const mapStateToProps = (state) => {
  return {
    inspImages: state.inspirationImages.sources,
    inspImagesIsOpen: state.inspirationImages.open,
    catTitle: (state.questions[state.activeQuestion] ? state.questions[state.activeQuestion].categoryTitle: ''),
    catColor: state.questions[state.activeQuestion] ? state.questions[state.activeQuestion].color : 'black',
    qTitle: state.questions[state.activeQuestion] ? state.questions[state.activeQuestion].title : '',
    gameID: state.gameId,
    scenDetails: state.scenDetails
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Game);
