import React from 'react';
import { connect } from 'react-redux';

const Content = ({ content }) => (
  <div
    style={{ textAlign: 'left', margin: '5px 15px' }}
    dangerouslySetInnerHTML={{ __html: content }}
  />
);

const mapStateToProps = (state) => {
  return {
    content: state.questions[state.activeQuestion]
      ? state.questions[state.activeQuestion].content
      : ''
  };
};

export default connect(mapStateToProps)(Content);
